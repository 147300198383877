<template>
  <blt-modal-dialog
    title="ID Image"
    type="small-dialog"
    :showDialog="showDialog"
    :cancelDisabled="loading"
    :confirmDisabled="loading"
    :clickOutsideToClose="true"
    :stateClass="'blt-id-uploader-dialog identification-wrapper'"
    :showFooter="false"
    @cancel="$emit('closeDialog')"
  >
    <template v-slot:dialog-content>
      <div class="limit-height">
        <blt-contentloader :contentLoader="loading"></blt-contentloader>
        <div class="image-container" v-for="image in identificationImages" :key="image.imageDataBase64">
          <div class="description">{{ getImageDescription(image.imageType) }}:</div>
          <div class="image">
            <img :src="image.encodedBase64" alt="" />
          </div>
        </div>
      </div>
    </template>
  </blt-modal-dialog>
</template>
<script lang="ts">
import { $inj } from "@/common/decorators/depinject";
import type { IdentifcationImage } from "@/common/models/IIdentificationImage";
import { ApplicantIdentificationImageFactory } from "@/common/services/services.module";
import {defineComponent, type PropType} from 'vue'

export default defineComponent({
  name: "IdentificationImageDialog",
  setup() {
    const applicantIdentificationImageFactory = $inj(ApplicantIdentificationImageFactory);
    return { applicantIdentificationImageFactory };
  },
  emits: ["closeDialog"],
  props: {
    uuid: { type: String, required: true },
    applicantId: {
      type: Number,
      required: true
    },
    identification: {
      type: Object,
      requied: true
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    selectedIdType: {
      type: Object as PropType<IOcrIdTypes>,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      identificationImages: [] as IdentifcationImage[]
    };
  },
  async created() {
    this.loading = true;
    this.identificationImages = await this.applicantIdentificationImageFactory.getImageList(
      this.uuid,
      this.applicantId,
      this.identification?.DTO?.identificationId,
      this.identification?.DTO?.images
    );
    this.loading = false;
  },
  computed: {
    getImageDescription() {
      return (imageType: string) => {
        return this.selectedIdType?.imageTypes?.find(obj => obj?.imageType === imageType)?.description;
      };
    }
  }
});
</script>

<style scoped>

.image-container .description {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  padding: 14px 0px 10px 0px;
  margin: 0;
}


.image {
  border: 1px dashed rgba(100, 103, 105, 0.75);
  padding: 8px 16px 8px 8px;
  text-align: center;
  background-color: var(--jha-button-default-background, var(--default-button-color));
  margin-bottom: 8px;
}
.image img {
  width: 100%;
  height: 100%;
}

footer {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}
.identification-wrapper {
  margin-bottom: 16px;
}

.image-container {
  width: 100%;
  color: var(--jh-color-gray-900);
  font-size: 14px;
  font-weight: 500;
}
.limit-height {
  max-height: fit-content;
  min-height: 200px;
  width: 100%;
}
:deep(.banno-web-dialog-content-wrapper) {
  max-height: calc(100vh - 230px);
  margin-bottom: 24px !important;
}

@media (max-width: 599px) {
  .limit-height {
    max-height: fit-content;
    width: 100%;
  }
  .image img {
    width: 100%;
    height: 100%;
  }
  .full-width1 p,
  .full-width2 p {
    margin-top: var(--jh-space-600);
    margin-bottom: var(--jh-space-200);
  }
}
</style>
