<script setup lang="ts">
import { RouterView } from "vue-router";
import { setJhaWcTheme, JHA_THEMES } from '@banno/jha-wc/src/styles/theme-provider.js';
import { setJhTheme } from "@jack-henry/jh-ui/utils/themeProvider.js";
import {VsgToastContainer} from '@jack-henry/jha-vue-components';
import { onMounted } from "vue";
import { $injByInterface } from "@/common/decorators/depinject";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import BltToaster from "@/common/components/bltToaster/bltToaster.vue";
import { CacheStore } from "@/common/services/connect/CacheStore";
import UatBanner from "@/common/components/uatBanner/uatBanner.vue";

const workspaceMetadataStore = $injByInterface<IWorkspaceMetadataStore>("IWorkspaceMetadataStore");

CacheStore().clear();
onMounted(() => {
  setJhaWcTheme(JHA_THEMES.JHA_ADMIN);
  setJhTheme();
});
</script>

<template>
    <uat-banner v-if="workspaceMetadataStore.languageConstants?.SHOW_UAT_BANNER"></uat-banner>
    <RouterView v-slot="{ Component }">
      <suspense timeout="0">
        <template #default>
          <component :is="Component"></component>
        </template>
      </suspense>
    </RouterView>
    <BltToaster v-if="workspaceMetadataStore.toast.showToast" />
    <vsg-toast-container class="toast-radius" />
</template>
