<template>
  <div>
    <blt-emp-form
      :disabled="false"
      :loading="isLoading || !fields"
      :backDisabled="false"
      :before-continue="beforeContinue"
      showCurrentApplicant
      currentStateCssClass="business-info-state-current-applicant"
      hideContinueLoader
      :workspace-uuid="currentWorkspaceUUID"
      :applicant-id="currentApplicantId"
      validation="BUSINESS_INFO"
      validation-type="APPLICANT">
      <form name="BusinessInfo" autocomplete="off">
        <div class="emp-details">
          <jha-form-text-input
            data-cy="business-info-business-name-input"
            v-if="isVisible(STATE_MSG.EMP_BUSINESS_INFO.BUSINESS_NAME)"
            v-blt-input-field="fields"
            :required="isRequired(STATE_MSG.EMP_BUSINESS_INFO.BUSINESS_NAME)"
            v-blt-ui-text="BranchLanguageKeysConstant.OPEN_BUSINESS_INFO_LABEL_BUSINESSNAME"
            :disabled="locked"
            v-model="businessInfoStore.business.businessName"
            @change="validateBusinessName"
            :warning="errorMessageDisplay"
            name="businessName"
            class="input mb-4"
            ref="businessName"
            outline
          >
          </jha-form-text-input>

          <jha-form-text-input
              data-cy="business-info-type-of-business-input"
              v-if="isVisible(STATE_MSG.EMP_BUSINESS_INFO.TYPE_OF_BUSINESS)"
              v-blt-ui-text="'branch_business_info_label_type_of_business'"
              :required="isRequired(STATE_MSG.EMP_BUSINESS_INFO.TYPE_OF_BUSINESS)"
              :disabled="locked"
              v-model="businessInfoStore.business.typeOfBusiness"
              @change="validateTypeOfBusiness"
              :warning="errorMessageDisplay"
              name="typeOfBusiness"
              class="input mb-4"
              ref="typeOfBusiness"
              outline
          >
          </jha-form-text-input>

          <jha-form-text-input
            data-cy="business-info-doing-business-as-input"
            v-blt-input-field="fields"
            v-if="isVisible(STATE_MSG.EMP_BUSINESS_INFO.DOING_BUSINESS_AS)"
            :required="isRequired(STATE_MSG.EMP_BUSINESS_INFO.DOING_BUSINESS_AS)"
            :warning="errorMessageDisplay"
            v-blt-ui-text="BranchLanguageKeysConstant.OPEN_BUSINESS_INFO_LABEL_DBA"
            :disabled="locked"
            v-model="businessInfoStore.business.dba"
            name="doingBusinessAs"
            class="input mb-4"
            outline
          >
          </jha-form-text-input>

          <bltLookupSelector
            data-cy="business-info-business-type-select"
            v-blt-input-field="fields"
            v-if="isVisible(STATE_MSG.EMP_BUSINESS_INFO.BUSINESS_TYPE)"
            :require="isRequired(STATE_MSG.EMP_BUSINESS_INFO.BUSINESS_TYPE)"
            :title="BranchLanguageKeysConstant.OPEN_BUSINESSINFO_LABEL_BUSINESS_TYPE"
            :disabled="locked"
            @lookupChange="updateBusinessType"
            :bltLookup="LOOKUPCONSTANT.BUSINESS"
            :value="businessInfoStore.business.businessTypeCode"
            :warning="errorMessageDisplay"
            ref="businessTypeCode"
          >
          </bltLookupSelector>
        </div>

        <div class="business-address">
          <blt-business-tax-id :blt-tax-id-types="taxIdTypes"
                               :disabled="locked"
                               check-tax-id
                               :workspace-uuid="workspaceStore.workspaceUUID"
                               :header-label="BranchLanguageKeysConstant.OPEN_BUSINESS_INFO_LABEL_TAX_ID_TYPE"
                               :ssn-label="BranchLanguageKeysConstant.OPEN_BUSINESS_INFO_OPTION_SSN"
                               :ein-label="BranchLanguageKeysConstant.OPEN_BUSINESS_INFO_OPTION_EIN">
          </blt-business-tax-id>

          <div class="business-address-naics">
            <bltSingleLookup
              data-cy="business-info-naics-code-select"
              :showClearButton="true"
              v-blt-input-field="fields"
              v-if="isVisible(STATE_MSG.EMP_BUSINESS_INFO.NAICS_CODE)"
              name="naicsCode"
              :value="businessInfoStore.business.naicsCode"
              :params="{enabled: true}"
              :lookupType="getLookupType"
              :typeCode="getNaicsList ?? ''"
              :placeholder="BranchLanguageKeysConstant.OPEN_BUSINESS_INFO_LABEL_NAICS"
              :fields="fields"
              :require="isRequired(STATE_MSG.EMP_BUSINESS_INFO.NAICS_CODE)"
              :disabled="locked"
              :errorMessageInvalid="errorInvalidInput"
              :errorMessageRequired="errorMessageDisplay"
              @onLookupChange="updateNaics($event)"
              @onError="updateNaics($event.detail)"
              @clear="businessInfoStore.business.naicsCode = undefined">
            ></bltSingleLookup>
            <blt-helper-link v-if="isVisible(STATE_MSG.EMP_BUSINESS_INFO.NAICS_CODE)"
                             :disclosure-code="'NAICS'"
                             :ui-key="'branch_business_info_naics_helper_text'">
            </blt-helper-link>
            <jha-form-date-input-icon
              data-cy="business-info-date-of-Establishment-input"
              v-if="isVisible(STATE_MSG.EMP_BUSINESS_INFO.DOE)"
              v-blt-input-field="fields"
              :required="isRequired(STATE_MSG.EMP_BUSINESS_INFO.DOE)"
              v-blt-ui-text="BranchLanguageKeysConstant.OPEN_BUSINESS_INFO_LABEL_DOE"
              :disabled="locked"
              v-model="businessInfoStore.business.dateOfEstablishment"
              class="input-date"
              ref="dateOfEstablishment"
              name="dateOfEstablishment"
              @change="validateDOE"
              outline
              :warning="errorMessageDisplay"
            >
            </jha-form-date-input-icon>

            <blt-lookup-selector
              data-cy="business-info-state-of-leagal-formation-select"
              v-blt-input-field="fields"
              @lookupChange="businessInfoStore.business.stateOfLegalFormation = $event.detail"
              :title="BranchLanguageKeysConstant.OPEN_BUSINESS_INFO_LABEL_STATE"
              :disabled="locked"
              :value="businessInfoStore.business.stateOfLegalFormation"
              :bltLookupOptions="{ enabled: true }"
              :bltLookup="LOOKUPCONSTANT.STATE"
              :warning="errorMessageDisplay"
              v-if="fields.length && isVisible(STATE_MSG.EMP_BUSINESS_INFO.STATE_OF_LEGAL_FORMATION)"
              :require="isRequired(STATE_MSG.EMP_BUSINESS_INFO.STATE_OF_LEGAL_FORMATION)"
              name="stateOfEstablishment"
            ></blt-lookup-selector>

            <bltLookupSelector
              data-cy="business-info-country-of-leagal-formation-select"
              v-blt-input-field="fields"
              @lookupChange="businessInfoStore.business.countryOfLegalFormation = $event.detail"
              :title="BranchLanguageKeysConstant.OPEN_BUSINESS_INFO_LABEL_COUNTRY"
              :disabled="locked"
              :value="businessInfoStore.business.countryOfLegalFormation"
              :bltLookupOptions="{ enabled: true }"
              :bltLookup="LOOKUPCONSTANT.COUNTRY"
              :warning="errorMessageDisplay"
              v-if="fields.length && isVisible(STATE_MSG.EMP_BUSINESS_INFO.COUNTRY_OF_LEGAL_FORMATION)"
              :require="isRequired(STATE_MSG.EMP_BUSINESS_INFO.COUNTRY_OF_LEGAL_FORMATION)"
              name="countryOfEstablishment"
            >
            </bltLookupSelector>

            <blt-business-info-for-profit channel="branch"
                                          v-if="isVisible(STATE_MSG.EMP_BUSINESS_INFO.FOR_PROFIT)"
                                          :disabled="locked"
                                          :required="getField('forProfit')?.required"
                                          @toggle="businessInfoStore.business.forProfit = $event"
                                          :value="businessInfoStore.business.forProfit"/>

            <blt-business-info-fincen :value="businessInfoStore.business.finCenEntityId"
                                      :disabled="locked"
                                      :required="getField('FinCEN')?.required"
                                      v-if="getField('FinCEN')?.enabled"
                                      channel="branch"
                                      @toggle="businessInfoStore.business.finCenEntityId = $event"/>
          </div>
        </div>
      </form>
    </blt-emp-form>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { $inj, $injByInterface } from "@/common/decorators/depinject";
import {
  ApplicantBusinessFactory,
  LanguageFactory,
  LookupFactory,
  UIFieldsFactory,
  BusinessTaxIdTypeService,
  BusinessTypesFactory,
  TaxIdValidationService
} from "@/common/services/services.module";
import { LOOKUPCONSTANT, LOOKUPSUPPORTCONSTANTS } from "@/common/constant/LookupConstant";
import { BusinessInfoConstant } from "@/common/constant/BusinessInfoConstant";
import REGEX from "@/common/enums/regexEnum";
import UI_FIELDS_CATEGORIES from "@/common/enums/UIFieldsEnum";
import type UIField from "@/common/data/UIFields";
import type IEmpWorkspaceStore from "@/branchmanager/stores/IEmpWorkspaceStore";
import { BranchLanguageKeysConstant } from "@/branchmanager/app/constant/BranchLanguageKeysConstant";
import type { Validation } from "@/common/models/IStateValidationResponse";
import type IApplicantValidationFactory from "@/common/services/Applicant/IApplicantValidationFactory";
import type BusinessTaxId from "@/common/data/BusinessTaxId";
import STATE_MSG from "@/common/messages/state.messages";
import WORKFLOW_TYPES from "@/common/enums/workflowTypesEnum";
import { workspaceStatusConstant } from "@/branchmanager/app/constant/workspaceStatusConstant";
import type INotificationFactory from "@/common/services/INotificationFactory";
import EmployeeWorkflowFactory from "@/branchmanager/services/EmployeeWorkflowFactory";
import BltBusinessTaxId from "@/common/components/bltBusinessTaxId/bltBusinessTaxId.vue";
import {BusinessInfoStore} from "@/common/stores/BusinessInfoStore";
import BltHelperLink from "@/common/components/bltHelperLink/bltHelperLink.vue";
import {ApplicantSearchStore} from "@/branchmanager/stores/ApplicantSearchStore";
import BltSingleLookup from "@/common/components/bltSingleLookup/bltSingleLookup.vue";
import {isEmpty} from "lodash";
import BltEmpForm from "@/branchmanager/app/components/bltForm/bltEmpForm.vue";
import BltBusinessInfoFincen from "@/common/components/bltBusinessInfo/bltBusinessInfoFincen.vue";
import BltBusinessInfoForProfit from "@/common/components/bltBusinessInfo/bltBusinessInfoForProfit.vue";
import BltLookupSelector from '@/common/components/bltLookupSelector/bltLookupSelector.vue'
import type IBusiness from "@/common/models/IBusiness";

export default defineComponent({
  name: "BusinessInfoState",
  components: {
    BltLookupSelector,
    BltEmpForm,
    BltHelperLink,
    BltBusinessTaxId,
    BltSingleLookup,
    BltBusinessInfoFincen,
    BltBusinessInfoForProfit
  },
  setup() {
    const workspaceStore = $injByInterface<IEmpWorkspaceStore>("IWorkspaceStore");
    const applicantBusinessFactory = $inj(ApplicantBusinessFactory);
    const applicantValidationFactory = $injByInterface<IApplicantValidationFactory>("IApplicantValidationFactory");
    const notificationFactory = $injByInterface<INotificationFactory>("INotificationFactory");
    const workflowFactory = $inj(EmployeeWorkflowFactory);
    const applicantSearchStore = ApplicantSearchStore();
    const languageFactory = $inj(LanguageFactory);
    const lookupFactory = $inj(LookupFactory);
    const uiFieldsFactory = $inj(UIFieldsFactory);
    const businessTaxIdTypeService = $inj(BusinessTaxIdTypeService);
    const businessTypesFactory = $inj(BusinessTypesFactory);
    const uniqueTaxIdValidation = $inj(TaxIdValidationService);
    const businessInfoStore = BusinessInfoStore()

    return {
      workspaceStore,
      applicantBusinessFactory,
      applicantValidationFactory,
      notificationFactory,
      workflowFactory,
      languageFactory,
      lookupFactory,
      uiFieldsFactory,
      businessTaxIdTypeService,
      businessTypesFactory,
      uniqueTaxIdValidation,
      businessInfoStore,
      applicantSearchStore
    };
  },
  data() {
    return {
      LOOKUPCONSTANT,
      BusinessInfoConstant,
      BranchLanguageKeysConstant,
      fields: [] as Array<UIField>,
      loading: true,
      business: {} as IBusiness,
      errorMessageDisplay: "",
      errorInvalidInput: "",
      maxLength: 9,
      forProfitOptions: [] as Array<{ typeCode: string; typeDescription: string }>,
      REGEX,
      STATE_MSG,
      einErrorMessage: "",
      einErrorMessageVerbiage: "",
      businessValidty: {
        businessNameValid: false,
        forProfit: false,
        taxIdSSNValid: false,
        taxIdEINValid: false,
        naicsCodeValid: false,
        dateOfEstablishmentValid: { value: "" as string | Date, valid: true as boolean },
        countryOfLegalFormationValid: false,
        businessTypeCodeValid: false
      },
      emptyString: "",
      taxIdTypes: {
        ein: true,
        ssn: true,
        einNotRequired: false,
        ssnNotRequired: false
      } as BusinessTaxId,
      duplicateTaxIdWarning: ""
    };
  },

  computed: {
    currentWorkspaceUUID(): string {
      return this.workspaceStore.workspace.workspaceUUID;
    },
    currentApplicantId() {
      return this.workspaceStore.applicant.applicantId;
    },
    locked(): boolean {
      return (
        this.workspaceStore.workspace?.statusCode === workspaceStatusConstant.CLOSED ||
        this.workspaceStore.applicant.locked
      );
    },
    hasError(): boolean {
      return this.workflowFactory.hasWorkflowError();
    },
    naicsCodeIsInvalid(): boolean | number {
      if(undefined !== this.businessInfoStore.business.naicsCode)  {
        return RegExp(/\D/).test(this.businessInfoStore.business.naicsCode)
      } else {
        return false
      }
    },
    formIsValid(): boolean {
      if (this.locked) return true;
      if (
          (!this.businessInfoStore.business.businessName && this.getField("businessName")?.required) ||
          (!this.businessInfoStore.business.typeOfBusiness && this.getField("typeOfBusiness")?.required) ||
          (!this.businessInfoStore.business.dba && this.getField("doingBusinessAs")?.required) ||
          (!this.businessInfoStore.business.businessTypeCode && this.getField( "businessType")?.required) ||
          (!this.businessInfoStore.business.naicsCode && this.getField( "naicsCode")?.required) ||
          (!this.businessInfoStore.business.dateOfEstablishment && this.getField( "dateOfEstablishment")?.required) ||
          (!this.businessInfoStore.business.countryOfLegalFormation && this.getField( "countryOfEstablishment")?.required) ||
          ((this.businessInfoStore.business.forProfit === undefined) && this.getField( "forProfit")?.required) ||
          ((this.businessInfoStore.business.finCenEntityId === undefined) && this.getField( "FinCEN")?.required) ||
          !this.applicantBusinessFactory.isTheTaxIdValid(this.businessInfoStore.business, this.taxIdTypes, this.businessInfoStore.businessValidity) ||
          this.businessInfoStore.einErrorMessage ||
          this.naicsCodeIsInvalid
      ) {
        return false;
      }
      return true;
    },
    isLoading(): boolean {
      return this.loading;
    },
    getLookupType(): string {
      return LOOKUPSUPPORTCONSTANTS.LOOKUP_TYPE + LOOKUPCONSTANT.NAICS.toLowerCase();
    },
    getNaicsList(): string | undefined {
      return this.businessInfoStore.business.naicsCode;
    },
    isTaxIdValid(): string {
      return this.duplicateTaxIdWarning;
    },
    showCoreTaxIdError(): boolean {
      return this.isTaxIdValid && this.isTaxIdValid.length > 0 ? true : false;
    },
    showEINErrorMessage(): boolean {
      return this.einErrorMessage && this.einErrorMessage.length > 0 ? true : false;
    },
  },
  async beforeMount() {
    this.fields = await this.uiFieldsFactory.get(UI_FIELDS_CATEGORIES.BRANCH.BUSINESS_INFO);
    this.errorInvalidInput = this.BranchLanguageKeysConstant.BRANCH_EMPLOYMENT_MATCH_ERROR;
    await Promise.all([
      this.languageFactory.get(this.BranchLanguageKeysConstant.OPEN_BUSINESS_INFO_FOR_PROFIT_TRUE),
      this.languageFactory.get(this.BranchLanguageKeysConstant.OPEN_BUSINESS_INFO_FOR_PROFIT_FALSE),
      this.languageFactory.get(this.BranchLanguageKeysConstant.OPEN_BUSINESS_INFO_EIN_ERROR)
    ]).then((response) => {
      this.einErrorMessageVerbiage = response[2];
    });
  },
  async created() {
    this.businessInfoStore.business = await this.getBusiness();
    this.businessInfoStore.business.finCenEntityId ??= true
    if (!this.businessInfoStore.business.applicantBusinessId) {
      this.setValuesFromSearch();
    }
    this.updateTaxIdTypes();
    this.changeEINFormat();
    this.loading = false;
    this.errorMessageDisplay = await this.languageFactory.get(this.BranchLanguageKeysConstant.ERROR_MESSAGE_REQUIRED);
  },

  methods: {
    setValuesFromSearch() {
      switch(this.applicantSearchStore.searchCategory) {
        case "businessname":
          this.businessInfoStore.business.businessName = this.applicantSearchStore.searchTerm
          break;
        case "taxid":
          this.businessInfoStore.business.taxId = this.applicantSearchStore.searchTerm
          break;
      }
    },
    getField(key: string): UIField | undefined {
      return this.fields?.find((field) => field.field == key);
    },
    getBusiness(): Promise<IBusiness> {
      this.loading = true;
      return this.applicantBusinessFactory
        .get(this.workspaceStore.workspaceUUID, this.workspaceStore.applicant.applicantId)
        .then((response) => {
          return (
            response || {
              taxIdType: BusinessInfoConstant.EIN,
              businessName: this.emptyString,
              countryOfLegalFormation: BusinessInfoConstant.DEFAULT_COUNTRY_CODE
            }
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearNotificationMessages(): void {
      if (!this.isLoading) {
        this.notificationFactory.clearWorkflowMessages();
      }
    },
    isRequired(dataField: string): boolean {
      if (!this.fields.length) return false;
      const result = this.fields.filter((el: UIField) => {
        return el.enabled && el.required && el.field === dataField;
      });
      return result.length > 0;
    },
    isVisible(dataField: string): boolean {
      if (!this.fields) return false;
      const result = this.fields.filter((el: UIField) => {
        return el.enabled && el.field === dataField;
      });
      return result.length > 0;
    },
    taxIdTypeChecked(taxIdType: string): void {
      this.businessInfoStore.business.taxIdType = taxIdType;
    },
    validateBusinessName(): void {
      this.businessInfoStore.businessValidity.businessNameValid = (this.$refs.businessName as HTMLInputElement).checkValidity();
    },
    validateTypeOfBusiness(): void {
      this.businessInfoStore.businessValidity.typeOfBusinessValid = (this.$refs.typeOfBusiness as HTMLInputElement).checkValidity();
    },
    updateTaxIdTypes(): void {
      if (this.businessInfoStore.business?.businessTypeCode) {
        this.businessTypesFactory.getTaxIdTypes(this.businessInfoStore.business.businessTypeCode).then((taxIdTypes) => {
          this.taxIdTypes = taxIdTypes;
        });
      }
    },
    updateBusinessType(event: CustomEvent<string>): void {
      this.businessInfoStore.business.businessTypeCode = event.detail;
      delete this.businessInfoStore.business.taxId;
      this.updateTaxIdTypes();
    },
    async coreTaxIdUniqueValidation(): Promise<any> {
      if (this.businessInfoStore.business.taxId) {
        return await this.uniqueTaxIdValidation
          .coreTaxIdUnique(this.currentWorkspaceUUID, this.businessInfoStore.business.taxId, WORKFLOW_TYPES.BUSINESS)
          .then((coreTaxIdUniqueMessage) => {
            return coreTaxIdUniqueMessage;
          })
          .catch((error) => {
            this.loading = false;
            throw error;
          });
      }
    },
    async duplicateTaxId(): Promise<void> {
      const validateREGEX = this.businessInfoStore.business.taxIdType === BusinessInfoConstant.SSN ? REGEX.TAX_ID : REGEX.EIN;
      if (!this.businessInfoStore.business.taxId || !validateREGEX.test(this.businessInfoStore.business.taxId)) {
        this.duplicateTaxIdWarning = this.errorMessageDisplay;
        return;
      }
      const validateExistingCoreId = await this.coreTaxIdUniqueValidation().catch((e) => {
        this.duplicateTaxIdWarning = e.message;
      });
      //If coreTax validtaion passes and workspace passed or it should not reach here
      if (validateExistingCoreId) {
        this.duplicateTaxIdWarning = "";
      }
    },
    validateEIN(): void {
      if (this.businessInfoStore.business.taxId && !this.businessInfoStore.business.taxId.includes("-")) {
        this.maxLength = 9;
      }
    },

    changeEINFormat(): void {
      if (!this.businessInfoStore.business.taxId) {
        this.einErrorMessage = this.errorMessageDisplay;
      } else if (this.businessInfoStore.business.taxId) {
        const validEin = REGEX.EIN.test(this.businessInfoStore.business.taxId) && this.businessInfoStore.business.taxId.toString().length >= 9;
        if (!validEin) {
          this.einErrorMessage = this.einErrorMessageVerbiage;
        } else {
          this.einErrorMessage = "";
        }
      }
      if (this.businessInfoStore.business.taxIdType === BusinessInfoConstant.EIN) {
        const ein: string = this.businessInfoStore.business.taxId || "";
        if (ein.length <= 2) {
          return;
        }
        const patt = new RegExp(STATE_MSG.EMP_BUSINESS_INFO.EIN_PATTERN);
        const res = patt.test(ein);
        if (!res) {
          const plainEIN: string = ein.match(REGEX.EIN_MATCH)?.join("") || "";
          const matchResult = plainEIN.match(REGEX.PLAIN_EIN);
          if (matchResult) {
            const fromatedEIN = matchResult.slice(1).join("-").replace(/-*$/g, "");
            if (fromatedEIN.length > 9) {
              this.businessInfoStore.business.taxId = fromatedEIN;
              this.maxLength = 10;
            } else {
              this.businessInfoStore.business.taxId = plainEIN;
              this.maxLength = 9;
            }
          }
        } else {
          this.maxLength = 10;
        }
      }
    },
    updateNaics(value: string): void {
      if (this.businessInfoStore.business.naicsCode !== value) {
        this.businessInfoStore.business.naicsCode = (value == "")
            ? undefined
            : value;
      }
    },
    validateDOE(): void {
      this.businessInfoStore.businessValidity.dateOfEstablishmentValid = {
        value: this.businessInfoStore.businessValidity.dateOfEstablishmentValid?.value,
        valid: (this.$refs.dateOfEstablishment as any).checkValidity()
      };
    },
    beforeContinue(): Promise<void> | undefined {
        this.businessInfoStore.business.taxId = this.businessInfoStore.business.taxId?.replace("-", "");
        return this.applicantBusinessFactory
          .save(this.workspaceStore.workspaceUUID, this.workspaceStore.applicant.applicantId, this.businessInfoStore.business)
          .then((response) => {
            this.businessInfoStore.business = response || this.businessInfoStore.business;
          })
    }
  }
});
</script>

<style scoped>
jha-flex-wrapper {
  gap: 8px;
  align-items: flex-start;
}

jha-flex-wrapper > * {
  flex: 2;
  margin-bottom: 0px;
}

.business-address {
  padding-bottom: 0px;
}

.show-ssn-margin {
  margin-bottom: 12px;
}
.show-ein-margin {
  margin-bottom: 12px;
}

.business-address2 {
  padding-bottom: 0px;
}

.business-typecode {
  margin-top: var(--jh-space-300);
  margin-top: var(--jh-space-600);
}

.input {
  margin-bottom: 0px;
}

.input-date {
  margin-bottom: 4px;
}

jha-form-radio-button {
  --jha-form-radio-margin-bottom: 4px;
  --jha-form-radio-size: 18px;
  --jha-form-radio-margin-top: 0px;
}

.tax-type {
  font-weight: 400;
}

.radio-input {
  margin-left: var(--jh-space-900);
}

.business-info-radio {
  margin-top: var(--jh-space-300);
}

.business-info-radio1 {
  padding-top: var(--jh-space-300);
}

:deep(.continue-header-wrapper) {
  margin-top: 0px;
}

:deep(.blt-form-footer) {
  padding-top: var(--jh-space-500);
}

@media (max-width: 468px) {
  jha-flex-wrapper {
    flex-wrap: wrap;
  }

  jha-flex-wrapper > * {
    flex-basis: 100%;
  }
}
</style>
