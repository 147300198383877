<script setup lang="ts">
import type TypeCode from '@/common/data/TypeCode'
import type IAccount from '@/common/models/IAccount'
import type {DeepReadonly} from 'vue'
import {VsgSelect, VsgTextInput} from '@jack-henry/jha-vue-components'
import LoanPurposeSelector from "@/common/components/productSelection/loanPurposeSelector.vue";
import LOAN_GROUPS from "@/common/components/productSelection/LoanGroupsEnum";
import {requireInject} from "@/common/utils/InjectUtil";
import {PRODUCT_SELECTION_INJECTION} from "@/common/services/Products/productSelectionInjection";

const props = defineProps<{
  intentions: Array<TypeCode>
  account: DeepReadonly<IAccount>
}>()

const injection = requireInject(PRODUCT_SELECTION_INJECTION)

const emits = defineEmits<{
  (e: 'updateAccount', account: DeepReadonly<IAccount>): void
}>()

const updateIntention = (event: CustomEvent<string>) => {
  emits('updateAccount', {...props.account, accountIntention: event.detail})
}

const updateDescription = (description: string) => {
  emits('updateAccount', {...props.account, description})
}

const isLoanProduct = (account: IAccount) => {
  return Object.keys(LOAN_GROUPS).includes(account.accountGroup)
}
</script>

<template>
  <div class="two-wide-grid"
       :class="{'ac-loan' : injection.channel == 'open' }">
    <vsg-select
      v-if="!isLoanProduct(props.account) && injection.channel == 'branch'"
      :options="intentions"
      empty-option-label=" "
      outline
      @change="updateIntention"
      labelKey="typeDescription"
      valueKey="typeCode"
      :value="account.accountIntention"
      label="Account Intention" />

    <loan-purpose-selector v-if="isLoanProduct(props.account)"
                           :account/>

    <vsg-text-input
      outline
      v-if="injection.channel == 'branch'"
      style="flex: 1 1 50%"
      @blur="updateDescription($event.target.value)"
      label="Account Description"
      :value="account.description" />
  </div>
</template>

<style scoped>
.two-wide-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--jh-space-200);
}

.two-wide-grid.ac-loan {
  grid-template-columns: 1fr;
}
</style>
