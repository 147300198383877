<template>
  <blt-modal-dialog
    :showDialog="showPrintDialog"
    :title="DialogConstant.PRINT_DIALOG_TITLE"
    showConfirm
    :confirmLabel="printButton"
    :confirmDisabled="disablePrintBtn"
    @confirm="print()"
    @cancel="$emit('togglePrintDisclosure')"
    type="small-dialog"
  >
    <template v-slot:dialog-content>
      <div class="print-container">
        <div class="print-disclosure">
          <div
            class="popup-list"
            v-if="
              printSavedApplicantDisclosurePackage?.disclosures?.length || printableEnrollmentDisclosurePackages?.length
            "
          >
            <div
              class="popup-list-item"
              v-for="disclosure in printSavedApplicantDisclosurePackage.disclosures"
              :key="disclosure.disclosureCode"
            >
              <jha-form-checkbox
                  :checked="disclosure.printActive"
                  @change="disclosure.printActive = !disclosure.printActive"
                  :disabled="!disclosure.allowPrint || isLoading"
                >
                <div class="m-tb-auto">
                  <jha-icon-document class="icon-pos secondary-fill-color"></jha-icon-document>
                </div>
                  <div class="primary-text normal-text">
                    {{ disclosure.disclosureTitle }}
                  </div>
              </jha-form-checkbox>
            </div>
          </div>
          <div
            class="disc-list"
            v-for="applicantDisclosurePackage in printSavedEnrollmentApplicantDisclosurePackage"
            :key="applicantDisclosurePackage.id"
          >
            <blt-current-applicant-with-status :applicant="applicantDisclosurePackage.applicant || {}" :inline="true" />
            <div
              class="popup-list-item"
              v-for="disclosure in applicantDisclosurePackage.disclosures"
              :key="disclosure.disclosureCode"
            >
                  <jha-form-checkbox
                    :checked="disclosure.printActive"
                    @change="disclosure.printActive = !disclosure.printActive"
                    :disabled="!disclosure.allowPrint || isLoading"
                  >
                  <div class="m-tb-auto">
                    <jha-icon-document class="icon-pos secondary-fill-color"></jha-icon-document>
                  </div>
                  <div class="details">
                    <div class="primary-text normal-text">
                      {{ disclosure.disclosureTitle }}
                    </div>
                  </div>
                  </jha-form-checkbox>
            </div>
          </div>
          <div
            class="disc-list"
            v-for="accountDisclosurePackage in printSavedEnrollmentAccountDisclosurePackage"
            :key="accountDisclosurePackage.id"
          >
            <blt-accounts :account="accountDisclosurePackage"></blt-accounts>
            <div
              class="popup-list-item"
              v-for="disclosure in accountDisclosurePackage.disclosures"
              :key="disclosure.disclosureCode"
            >
                <jha-form-checkbox
                    :checked="disclosure.printActive"
                    @change="disclosure.printActive = !disclosure.printActive"
                    :disabled="!disclosure.allowPrint || isLoading"
                  >
                  <div class="m-tb-auto">
                    <jha-icon-document class="icon-pos secondary-fill-color"></jha-icon-document>
                  </div>
                  <div class="details">
                    <div class="primary-text normal-text">
                      {{ disclosure.disclosureTitle }}
                    </div>
                  </div>
                </jha-form-checkbox>
            </div>
          </div>
        </div>
      </div>
    </template>
  </blt-modal-dialog>
</template>

<script lang="ts">
import type {PropType} from "vue";
import {defineComponent} from "vue";
import "@banno/jha-wc/src/forms/jha-form-email-input/jha-form-email-input.js";
import {DialogConstant} from "@/common/constant/DialogConstant";
import "@banno/jha-wc/src/forms/jha-form-select/jha-form-select.js";
import {SendDisclosureModalFactory} from "@/common/services/services.module";
import type {Disclosure, IEnrollmentAccountPackage} from "@/common/models/IDisclosure";
import {$inj, $injByInterface} from "@/common/decorators/depinject";
import type IEmpWorkspaceStore from "@/branchmanager/stores/IEmpWorkspaceStore";
import STATE_MSG from "@/common/messages/state.messages";
import type IWorkspace from "@/common/models/IWorkspace";
import {workspaceStatusConstant} from "@/branchmanager/app/constant/workspaceStatusConstant";
import type IApplicant from "@/common/models/IApplicant";
import {cloneDeep} from "lodash";
import EmployeeWorkflowFactory from "@/branchmanager/services/EmployeeWorkflowFactory";

export default defineComponent({
  name: "bltPrintDisclosureDialog",
  emits: ["sendPrintDisclosure", "togglePrintDisclosure"],
  props: {
    savedApplicantDisclosurePackage: {
      type: Object as PropType<IEnrollmentAccountPackage>,
      default: {} as IEnrollmentAccountPackage
    },
    savedEnrollmentAccountDisclosurePackage: {
      type: Array<IEnrollmentAccountPackage>,
      default: [] as IEnrollmentAccountPackage[]
    },
    savedEnrollmentApplicantDisclosurePackage: {
      type: Array<IEnrollmentAccountPackage>,
      default: [] as IEnrollmentAccountPackage[]
    },
    showPrintDialog: {
      type: Boolean,
      default: false
    },
    workspaceUUID: {
      type: String,
      required: true
    },
    enrollmentId: {
      type: Number
    },
    enrollmentApplicants: {
      type: Array<IApplicant>
    },
    context: {
      required: true,
      type: String
    }
  },
  setup() {
    const sendDisclosureModalFactory = $inj(SendDisclosureModalFactory);
    const workspaceStore = $injByInterface<IEmpWorkspaceStore>("IWorkspaceStore");
    const workflowFactory = $inj(EmployeeWorkflowFactory);


    return {
      sendDisclosureModalFactory,
      workspaceStore,
      workflowFactory
    };
  },

  data() {
    return {
      DialogConstant,
      printSavedApplicantDisclosurePackage: {} as IEnrollmentAccountPackage,
      printSavedEnrollmentApplicantDisclosurePackage: [] as IEnrollmentAccountPackage[],
      printSavedEnrollmentAccountDisclosurePackage: [] as IEnrollmentAccountPackage[],
      state: STATE_MSG.EMP_DISCLOSURE.LIST,
      sendType: STATE_MSG.EMP_DISCLOSURE.PRINT,
      isLoading: false,
      printButton: STATE_MSG.EMP_DISCLOSURE.PRINT_BUTTON,
      enrollmentDisclosuresPackage: [] as Disclosure[]
    };
  },
  created() {
    this.togglePrintAll();
  },
  watch: {
    savedApplicantDisclosurePackage: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.togglePrintAll();
      }
    }
  },
  computed: {
    disablePrintBtn(): boolean {
      return !this.anySelected() || this.isLoading || this.isWorkspaceClosed;
    },
    getConcatPackages(): IEnrollmentAccountPackage[] {
      return [this.savedApplicantDisclosurePackage]
        .concat(this.printSavedEnrollmentApplicantDisclosurePackage)
        .concat(this.printSavedEnrollmentAccountDisclosurePackage);
    },
    currentWorkspace(): IWorkspace {
      return this.workspaceStore.workspace;
    },
    isWorkspaceClosed(): boolean {
      return this.currentWorkspace?.statusCode === workspaceStatusConstant.CLOSED;
    },
    printableEnrollmentDisclosurePackages(): IEnrollmentAccountPackage[] {
      return [
        ...this.printSavedEnrollmentAccountDisclosurePackage,
        ...this.printSavedEnrollmentApplicantDisclosurePackage
      ];
    }
  },
  methods: {
    togglePrintAll(): void {
      this.printSavedApplicantDisclosurePackage = this.savedApplicantDisclosurePackage
        ? this.sendDisclosureModalFactory.setAllPrintActive([this.savedApplicantDisclosurePackage])[0]
        : ({} as IEnrollmentAccountPackage);
      this.printSavedEnrollmentApplicantDisclosurePackage = this.savedEnrollmentApplicantDisclosurePackage
        ? this.sendDisclosureModalFactory.setAllPrintActive(cloneDeep(this.savedEnrollmentApplicantDisclosurePackage))
        : ([] as IEnrollmentAccountPackage[]);
      this.printSavedEnrollmentAccountDisclosurePackage = this.savedEnrollmentAccountDisclosurePackage
        ? this.sendDisclosureModalFactory.setAllPrintActive(cloneDeep(this.savedEnrollmentAccountDisclosurePackage))
        : ([] as IEnrollmentAccountPackage[]);
    },
    anySelected(): boolean {
      return this.sendDisclosureModalFactory.anySelected(this.getConcatPackages, this.sendType);
    },
    print(): void {
      if (!this.anySelected()) {
        return;
      }
      this.workflowFactory.setBeginPreloadingStatus();
      let printPromise;
      if (this.context === "APPLICANT") {
        printPromise = this.sendDisclosureModalFactory.printApplicantDisclosurePackage(
          this.workspaceUUID,
          this.printSavedApplicantDisclosurePackage
        );
      } else {
        printPromise = this.sendDisclosureModalFactory.printEnrollmentDisclosurePackages(
          this.workspaceUUID,
          this.enrollmentApplicants ? this.enrollmentApplicants[0].applicantId : 0,
          this.enrollmentId || this.workspaceStore.enrollment.enrollmentId,
          this.getConcatPackages
        );
      }
      printPromise
        .then(() => {
            setTimeout(() => {
              this.$emit("sendPrintDisclosure");
            }, 500);
          },
          () => {
            setTimeout(() => {
              this.$emit("togglePrintDisclosure");
            }, 500);
          }
        )
        .finally(() => {
          this.workflowFactory.setEndPreloadingStatus();
        });
    }
  }
});
</script>

<style scoped>
.disc-list {
  margin-bottom: var(--jh-space-400);
}
.disc-list:last-child {
  margin-bottom: 0px;
}
:host {
  width: 100%;
}
jha-flex-wrapper {
  gap: 8px;
  align-items: flex-start;
}

jha-flex-wrapper > * {
  flex: 1;
  margin-bottom: 4px;
}

jha-form-floating-group {
  margin-bottom: 4px;
}
.print-container {
  width: 100%;
  max-height: calc(100vh - 260px);
  overflow-y: auto;
  --secondary-content-background-color: #f5f5f5;
}
.print-disclosure {
  overflow: auto;
  padding: var(--jh-space-600) 0 var(--jh-space-600) var(--jh-space-600);
}

.popup-list-item {
  border-bottom: 1px solid var(--jha-border-color);
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.popup-list-item jha-form-checkbox{
  width: 100%;
  font-size:14px;
}
jha-form-checkbox {
  --jha-form-checkbox-padding: var(--jh-space-400) var(--jh-space-600) var(--jh-space-400) 0px;
   --jha-form-checkbox-font-size: 14px;
  margin-bottom: 0px;
}

.popup-list-item:last-child {
  border-bottom: 0px solid;
}

@media only screen and (max-width: 468px) {
  .print-container {
    max-height: calc(100vh - 132px);
  }
  .popup-list-item {
    margin-left: 0;
    padding-right: 16px;
  }
  .print-disclosure {
    overflow: auto;
    padding: var(--jh-space-600) 0 var(--jh-space-600) var(--jh-space-400);
  }
  jha-form-checkbox {
  --jha-form-checkbox-padding: var(--jh-space-400) 0px var(--jh-space-400) 0px;
  }
}
</style>
<style>
.print-container .print-disclosure .current-applicant-header,
.print-disclosure .disclosure-heading {
  margin: 0px 24px 0 0;
  --jha-icon-fill-color: var(--body-text-secondary-color);
  padding: 8px;
}
.print-disclosure .applicant-details {
  display: flex;
  align-items: center;
  gap: 4px;
}
.print-disclosure .applicant-details .primary-text {
  padding-top: 2px;
  color: var(--body-text-secondary-color);
  font-size:12px;
}
.print-disclosure .applicant-icons > div {
  padding-right: 8px;
}
@media screen and (max-width: 468px) {
  .print-disclosure .applicant-details {
    align-items: center;
  }
  .print-disclosure .applicant-details .primary-text,
  .print-disclosure .applicant-details .secondary-text {
    max-width: calc(100vw - 100px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .print-disclosure .current-applicant-header,
  .print-disclosure .disclosure-heading {
    margin: 0px 16px 0 0;
  }
  .print-container .print-disclosure .current-applicant-header,
  .print-disclosure .disclosure-heading {
    margin: 0px 16px 0 0;

  }
}
</style>
