<script setup lang="ts">
import {requireInject} from '@/common/utils/InjectUtil'
import {PRODUCT_SELECTION_INJECTION} from '@/common/services/Products/productSelectionInjection'
import {computed} from 'vue'
import {VsgFlexWrapper, VsgIconChevronRight, VsgIconHeartbeat, VsgListItem} from '@jack-henry/jha-vue-components'
import ProductSelectionIraIcon from '@/common/components/productSelection/product-selection-ira-icon.vue'
import BltHelperLink from '@/common/components/bltHelperLink/bltHelperLink.vue'
import {BltSearch} from '@/common/services/Search/SearchFactory'
import {$inj} from '@/common/decorators/depinject'
import {IAccountTypeGroup} from "@/common/services/Products/accountGroupService";
import TFA_GROUPS from "@/common/components/productSelection/TfaGroupsEnum";
import type {Product} from "@/common/models/IProductSelection";
import ProductTypeIcon from "@/common/components/product-type-icon/productTypeIcon.vue";

const injection = requireInject(PRODUCT_SELECTION_INJECTION)
const bltSearch = $inj(BltSearch)

const products = computed(() =>
    injection.values.productList.value.filter((product) => {
      return Object.keys(TFA_GROUPS).includes(product.group)
    })
)

const productsSearched = computed(() => {
  return bltSearch.for(products.value, ['major', 'minor', 'description'], injection.values.searchTerm?.value ?? '')
})

const filterSubgroupWithAccount = (group: IAccountTypeGroup) => {
  return injection.values.productList.value
      .filter(product => product.group == group.typeCode)
      .length
}

const hasAnyResults = computed(() => {
  return productsSearched.value.length > 0
})

const selectSubgroup = (subgroup: IAccountTypeGroup) => {
  injection.methods.setSelectedSubgroup(subgroup)
  injection.methods.goToPane('PRODUCT_SUBGROUP')
}
</script>
<template>
  <div v-if="hasAnyResults" class="jh-font-heading-medium-1">Tax favored account</div>
  <vsg-list-item
      v-for="(product, index) in productsSearched"
      @click="injection.methods.addProduct(product)"
      :key="product.productId"
      :class="{'last-product': index === productsSearched.length - 1}">

    <vsg-flex-wrapper>
      <product-type-icon fill-color="var(--jha-color-primary)"
                         :group-type-code="product.group"/>
      <div class="jh-font-body-regular-1 jha-text-dark">{{ product.description }}</div>
      &nbsp;
      <blt-helper-link
          v-if="product.disclosureCode"
          :disclosure-code="product.disclosureCode" />
    </vsg-flex-wrapper>
    <vsg-icon-chevron-right slot="right" />
  </vsg-list-item>
</template>

<style scoped>
@import '../../../branchmanager/assets/typography.css';
@import '../../../branchmanager/assets/typography-colors.css';

jha-list-item {
  cursor: pointer;
  --jha-list-item-horizontal-spacing: 0;
  --jha-list-item-vertical-spacing: var(--jh-space-300);
}

.last-product {
  margin-bottom: var(--jh-space-400);
  border-bottom: 0;
}

.product-icon {
  fill: var(--jha-color-primary);
  margin-right: var(--jh-space-200);
}

jha-icon-chevron-right {
  fill: var(--account-card-secondary-background-color);
}

jha-list-item {
  --jha-list-item-horizontal-spacing: 0;
  margin-left: 0;
}
</style>
