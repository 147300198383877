import {Service} from '@/common/dependency.configs'
import CrudRepository from '@/common/services/Resource/CrudRepository'
import {inject} from 'inversify'
import {HttpHandler} from '@/common/services/services.module'
import type {DeepReadonly, Ref} from "vue";
import type {InjectionKey} from "vue/dist/vue";

@Service
export class ProductTypesService extends CrudRepository<NewProduct, Product, null> {
  constructor(@inject(HttpHandler) httpHandler: HttpHandler) {
    super(
      httpHandler,
      () => `{{boltsbranch}}/accounttype`,
      (entity) => entity?.productId
    )
  }
}

interface ProductInjection {
  product: DeepReadonly<Ref<Product | undefined>>
  setProduct: (product: Product) => void
  setProductFn: (fn: () => Promise<Product>) => void
  loading: DeepReadonly<Ref<Boolean>>
}

export const PRODUCT_INJECTION: InjectionKey<ProductInjection> = Symbol('admin-products')

export interface Product {
  accountDescription: string
  productId: number
  accountMajor: string
  accountMinor?: string
  subgroup?: string
  accountMajorDescription: string
  accountMinorDescription: string
  accountTypeGroupCode: string
  enabled: boolean
  businessEnabled: boolean
  personEnabled: boolean
  displayOrder?: number
  productLocation: 'GLOBAL' | 'REGIONAL'
  disclosurePackageId?: number
  productFeaturesDocCode?: string
  enabledApplicantChannel: boolean
  enabledEmployeeChannel: boolean
  synergyCabinet?: string
}

export interface NewProduct {
  productId?: number
  accountMajor: string
  accountMinor: string
  accountDescription: string
  accountTypeGroupCode: string
  enabled?: boolean
  businessEnabled?: boolean
  personEnabled?: boolean
  displayOrder?: number
  productLocation?: 'GLOBAL' | 'REGIONAL'
  disclosurePackageId?: number
  productFeaturesDocCode?: string
  enabledApplicantChannel?: boolean
  enabledEmployeeChannel?: boolean
  synergyCabinet?: string
}
