import {HttpHandler} from "@/common/services/services.module";
import {clientError} from "@/common/data/RestResponse";
import {inject, injectable, unmanaged} from "inversify";
import type CacheableAxiosRequestConfig from "@/common/services/connect/CacheableAxiosRequestConfig";
import ResponseTypes from "@/common/enums/responseTypesEnum";

@injectable()
export default class CrudRepository<New, Existing, Args> {

  constructor(
    @inject(HttpHandler) private httpHandler: HttpHandler,
    @unmanaged() private pathBuilder: (args: Args) => string,
    @unmanaged() private getId: (entity: Existing | New) => string | number | undefined | null
  ) {
  }

  async getAll(args: Args, config?: CacheableAxiosRequestConfig): Promise<Array<Existing>> {
    return this.httpHandler.get(this.pathBuilder(args), config, ResponseTypes.Payload);
  }

  async getOne(args: Args, id: number | string, config?: CacheableAxiosRequestConfig): Promise<Existing> {
    return this.httpHandler.get(this.pathBuilder(args) + `/${id}`, config, ResponseTypes.Payload);
  }

  async create(args: Args, entity: New, config?: CacheableAxiosRequestConfig): Promise<Existing> {
    return this.httpHandler.post(this.pathBuilder(args), entity, config, ResponseTypes.Payload);
  }

  async update (args: Args, entity: Existing, config?: CacheableAxiosRequestConfig): Promise<Existing> {

    const id = this.getId(entity)

    if(null != id) {
      return this.httpHandler.put(this.pathBuilder(args) + `/${id}`, entity, config, ResponseTypes.Payload);
    } else {
      console.error("Unexpectedly tried to update an entity that has not yet been saved", { entity: entity })
      return Promise.reject(clientError("Something went wrong, please try again later..."))
    }

  }

  async delete (args: Args, entity: Existing, config?: CacheableAxiosRequestConfig): Promise<void> {
    const id = this.getId(entity)
    return this.httpHandler.delete(this.pathBuilder(args) + `/${id}`, config, ResponseTypes.Payload)
  }

  async save(args: Args, entity: Existing | New, config?: CacheableAxiosRequestConfig): Promise<Existing> {
    return this.getId(entity)
      ? await this.httpHandler.put(this.pathBuilder(args) + `/${this.getId(entity)}`, entity, config, ResponseTypes.Payload)
      : await this.httpHandler.post(this.pathBuilder(args), entity, config, ResponseTypes.Payload);
  }

}
