<script setup lang="ts">
	import { VsgBaseInput } from '@jack-henry/jha-vue-components'
  import "@banno/jha-wc/src/forms/jha-form-input-base/jha-form-input.js";
  import {onMounted, ref, watch} from 'vue'
  import dayjs from "dayjs";

	const props = defineProps<{
	  required?: boolean;
	  name?: string;
	  warning?: string;
	  disabled?: boolean;
    maxDate?: Date;
    minDate?: Date;
    showWarning?: boolean;
	}>();

	// This is the value inside of this component (uses "/")
	const value = ref<string>();
	// This is the value from :model in the parent component (uses "-")
	const model = defineModel<string>();
  const showError = ref(false);
	const validValueRegex = /[0-1][0-9]\/[0-3][0-9]\/[1-2][0-9]{3}/;

	const onChange = (event: CustomEvent<string>) => {
		const currentValue = event.detail.split('/');

		// Handles when the user deletes any of the digits of the month
		if ((currentValue.length === 4 || currentValue.length === 3) && currentValue[1] === '') {
			model.value = '';
		// Handles when the user deletes any of the digits of the day
		} else if (currentValue.length === 4 && currentValue[2] === '') {
			const month = currentValue[0];
			model.value = `${month}/`;
		} else {
			model.value = currentValue.join('/');
		}

	  if (currentValue.join('/').match(validValueRegex)) {
			const [month, day, year] = currentValue;
			model.value =`${year}-${month}-${day}`;
	  }
	}

	onMounted(() => {
	  updateValue();
	});

	watch(() => model.value, () => {
	  updateValue();
	});

  const updateValue = () => {
	  if (model.value?.includes('-')) {
   		const [year, month, day] = model.value.split('-');
    	value.value = `${month}/${day}/${year}`;
		} else {
			value.value = model.value;
		}
	}

	const validityCheck = () => {
    // For fields not required
    if (!value.value && !props.required) {
      return;
    }

    if (!value.value?.match(validValueRegex)) {
      showError.value = true
      return;
    }

    if (props.maxDate
        && dayjs(value.value).isAfter(dayjs(props.maxDate))) {
      showError.value = true
      return;
    }

    if (props.minDate
        && dayjs(value.value).isBefore(dayjs(props.minDate))) {
      showError.value = true
      model.value = undefined
      return;
    }

    showError.value = false
    return false;
	}

  defineExpose({
    showError
  })

	</script>

	<template>
	  <vsg-base-input
	    outline
	    :value
	    @change="onChange"
      @blur="validityCheck"
	    :required="required"
	    :name="name"
      :warning
	    inputMode="numeric"
	    maxLength="10"
	    assistiveText="MM/DD/YYYY"
			allowedCharacters="[0-9]+"
	    mask="__/__/____"
	    :showWarning="showError || showWarning"
	    :disabled="disabled" />
	</template>

	<style scoped>
	jha-form-input {
	  --error-text-top-margin: 0;
	}
	</style>