<template>
  <div class="blt-list-card-wrapper">
    <jh-card>
      <div
        slot="jh-card-header"
        class="blt-list-card-header d-flex"
        v-if="!loading"
      >
        <slot name="header">
          <div class="blt-list-card-title-section">
            <div class="blt-list-card-number">{{ number }}</div>
            <div class="blt-list-card-title">{{ title }}</div>
          </div>
          <div class="blt-list-card-title-action" v-if="!hideAction && action && actionTitle">
            <jha-button link @click="action"  @keydown.enter="action" :data-cy="`workspace-action-btn-${actionTitle}`">
              {{ actionTitle }}
            </jha-button>
          </div>
        </slot>
      </div>
      <div class="blt-list-card-body">
        <div   v-if="list.length && !loading">
        <div class="divider" v-if="list.length"></div>
        <slot name="listItem" :item="item" v-for="item in list" :key="item"></slot>
        </div>
        <div class="loader-div1" v-else>
          <blt-contentloader :contentLoader="loading" class="loader-div"></blt-contentloader>
        </div>
      </div>
    
    </jh-card>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "bltListCard",
  props: {
    loading: {
      type: Boolean,
      required: true
    },
    list: {
      type: Array,
      required: false,
      default: () => []
    },
    title: {
      type: String,
      required: false,
      default: ""
    },
    number: {
      type: String,
      required: true
    },
    actionTitle: {
      type: String,
      required: false,
      default: ""
    },
    action: {
      type: Function,
      required: false
    },
    hideAction: {
      type: Boolean,
      required: false,
      default: false
    },
    isBannerPresent: {
      type: Boolean,
      default: false
    }
  }
});
</script>
<style scoped>
.blt-list-card-title-section {
  display: flex;
}

.blt-list-card-number {
  width: var(--jh-space-600);
}

.blt-list-card-wrapper {
  margin-top: var(--jh-space-400);
}

.blt-list-card-header {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  padding: var(--jh-space-200) 0;
  justify-content: space-between;
}

.blt-list-card-body {
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
}
.loader-div {
  padding: 64px 10px;
}

.divider {
  margin-left: 0px !important;
}

.card-loader {
  padding: 64px 10px 64px 10px;
}

@media (max-width: 468px) {
  .blt-list-card-header {
    padding: 30px var(--jh-space-400);
    height: auto;
  }
  .blt-list-card-body {
    padding-left: var(--jh-space-400);
  }
}
</style>