<script setup lang="ts">
import {requireInject} from '@/common/utils/InjectUtil'
import {PRODUCT_SELECTION_INJECTION} from '@/common/services/Products/productSelectionInjection'
import {computed} from 'vue'
import BltEmptyData from '@/common/components/bltEmptyData/bltEmptyData.vue'
import ProductSelectionIraIcon from '@/common/components/productSelection/product-selection-ira-icon.vue'
import {
  VsgFlexWrapper,
  VsgIconChevronRight,
  VsgIconHeartbeat,
  VsgList,
  VsgListItem
} from '@jack-henry/jha-vue-components'
import type {Product} from '@/common/models/IProductSelection'
import BltHelperLink from '@/common/components/bltHelperLink/bltHelperLink.vue'
import TFA_GROUPS from '@/common/components/productSelection/TfaGroupsEnum'
import ProductTypeIcon from '@/common/components/product-type-icon/productTypeIcon.vue'

const injection = requireInject(PRODUCT_SELECTION_INJECTION)

const products = computed(() => {
  return injection.values.productList.value.filter((product) => {
    return product.group == injection.values.selectedSubgroup.value?.typeCode
  })
})

const icon = computed(() => {
  switch (injection.values.selectedSubgroup.value?.typeCode) {
    case TFA_GROUPS.ROTH_IRA:
    case TFA_GROUPS.TRADITIONAL_IRA:
      return 'IRA'
    case TFA_GROUPS.SINGLE_HSA:
    case TFA_GROUPS.FAMILY_HSA:
      return 'HSA'
  }
})
</script>
<template>
  <blt-empty-data
    v-if="!products.length"
    blt-text="No products are available for the selected group.">
    <template #jhaIcon>
      <product-type-icon
        v-if="injection.values.selectedSubgroup.value?.typeCode"
        fill-color="var(--jha-color-primary)"
        :group-type-code="injection.values.selectedSubgroup.value?.typeCode" />
    </template>
  </blt-empty-data>

  <vsg-list v-if="products.length">
    <vsg-list-item
      v-for="product in products"
      @click="injection.methods.addProduct(product)"
      :key="product.productId">
      <vsg-flex-wrapper>
        <product-type-icon
          v-if="product.group"
          fill-color="var(--jha-color-primary)"
          :group-type-code="product.group" />
        <div class="jh-font-body-regular-1 jha-text-dark">{{ product.description }}</div>
        &nbsp;
        <blt-helper-link
          v-if="product.disclosureCode"
          :disclosure-code="product.disclosureCode" />
      </vsg-flex-wrapper>
      <vsg-icon-chevron-right slot="right" />
    </vsg-list-item>
  </vsg-list>
</template>

<style scoped>
@import '../../../branchmanager/assets/typography.css';
@import '../../../branchmanager/assets/typography-colors.css';

jha-list-item {
  cursor: pointer;
  --jha-list-item-horizontal-spacing: 0;
  --jha-list-item-vertical-spacing: var(--jh-space-300);
}

.product-empty-icon {
  fill: var(--account-card-secondary-background-color);
}

.product-icon {
  fill: var(--jha-color-primary);
  margin-right: var(--jh-space-200);
}

jha-icon-chevron-right {
  fill: var(--account-card-secondary-background-color);
}

jha-list-item {
  --jha-list-item-horizontal-spacing: 0;
  margin-left: 0;
}
</style>
