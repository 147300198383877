<script setup lang="ts">
import type IAccount from '@/common/models/IAccount'
import {computed, type DeepReadonly, onMounted, ref, watchEffect} from 'vue'
import {VsgSelect} from '@jack-henry/jha-vue-components'
import {requireInject} from '@/common/utils/InjectUtil'
import {PRODUCT_SELECTION_INJECTION} from '@/common/services/Products/productSelectionInjection'
import {$inj} from '@/common/decorators/depinject'
import ProductLoanPurposeService, {
  type ProductLoanPurpose
} from '@/common/services/LoanPurposes/productLoanPurposeService'

const props = defineProps<{
  account: DeepReadonly<IAccount>
}>()

const productLoanPurposeService = $inj(ProductLoanPurposeService)

const isLoading = ref(false)
const productLoanPurposes = ref<Array<ProductLoanPurpose>>([])

const injection = requireInject(PRODUCT_SELECTION_INJECTION)

onMounted(() => {
  isLoading.value = true

  productLoanPurposeService
    .getAll(props.account.accountTypeId, {cache: true})
    .then((response) => {
      productLoanPurposes.value = response.data.payload

      if (productLoanPurposes.value.length === 1 && !accountPurposeTypeCode.value) {
        updateLoanPurpose({detail: productLoanPurposes.value[0].purposeTypeCode})
      }
    })
    .finally(() => {
      isLoading.value = false
    })
})

const accountPurposeTypeCode = computed(() => {
  return injection.values.accountLoanPurposes.value.get(props.account.accountId)?.purposeTypeCode
})

const loanPurposeTypesWithDescription = computed(() => {
  return productLoanPurposes.value.map((purpose) => ({
    purposeTypeCode: purpose.purposeTypeCode,
    purposeTypeDescription:
      injection.values.loanPurposeTypes.value.find((t) => t.typeCode == purpose.purposeTypeCode)?.typeDescription ?? ''
  }))
})

const updateLoanPurpose = (event: CustomEvent<string> | {detail: string}) => {
  injection.methods.updateAccountLoanPurpose({accountId: props.account.accountId, purposeTypeCode: event.detail})
}
</script>

<template>
  <div>
    <vsg-select
      v-if="!isLoading"
      :options="loanPurposeTypesWithDescription"
      empty-option-label=" "
      class="pb-16"
      outline
      required
      @change="updateLoanPurpose"
      labelKey="purposeTypeDescription"
      valueKey="purposeTypeCode"
      :value="accountPurposeTypeCode"
      label="Loan purpose" />
  </div>
</template>
