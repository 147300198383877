<script setup lang="ts">
import {requireInject} from '@/common/utils/InjectUtil'
import {PRODUCT_SELECTION_INJECTION} from '@/common/services/Products/productSelectionInjection'
import {VsgButton, VsgFlexWrapper, VsgList, VsgListItem} from '@jack-henry/jha-vue-components'
import {computed, onMounted, ref} from 'vue'
import {LanguageFactory, LookupFactory} from '@/common/services/services.module'
import {$inj} from '@/common/decorators/depinject'
import type TypeCode from '@/common/data/TypeCode'
import ProductSelectionAccountIntention from '@/common/components/productSelection/productSelectionAccountIntention.vue'
import ProductTypeIcon from "@/common/components/product-type-icon/productTypeIcon.vue";
import LoanPurposeSelector from "@/common/components/productSelection/loanPurposeSelector.vue";
import LOAN_GROUPS from "@/common/components/productSelection/LoanGroupsEnum";
import type {IAccount} from "@/common/models/IWk";
import BltAccountBlock from "@/common/components/bltAccountBlock/bltAccountBlock.vue";

const languageFactory = $inj(LanguageFactory)
const lookupFactory = $inj(LookupFactory)

const injection = requireInject(PRODUCT_SELECTION_INJECTION)

const accounts = computed(() => injection.values.accountList.value)

const intentions = ref<Array<TypeCode>>([])
const loading = ref(true)

const getIntentions = () => {
  return injection.channel == 'branch' ? lookupFactory.Intention?.getAll({}, {cache: true}) : Promise.resolve()
}

onMounted(() => {
  getIntentions()
    .then((response: Array<TypeCode>) => {
      intentions.value = response
    })
    .finally(() => {
      loading.value = false
    })
})
</script>
<template>
  <span
    v-if="injection.channel == 'open'"
    v-blt-ui-text="`${injection.channel}_product_selection_no_account_body`"></span>
  <vsg-list borderless>
    <div
      :class="{'list-border-top': $index > 0}"
      v-for="(account, $index) in accounts"
      :key="account.accountId">
      <vsg-list-item borderless>
        <blt-account-block :account/>

        <div slot="right">
          <vsg-button
            @click="injection.methods.deleteAccount(account)"
            tertiary>
            Remove
          </vsg-button>
        </div>
      </vsg-list-item>

      <product-selection-account-intention
        @updateAccount="injection.methods.updateAccount"
        :account
        :intentions
        v-if="!loading" />
    </div>
  </vsg-list>
</template>
<style scoped>
@import '../../../branchmanager/assets/typography.css';
@import '../../../branchmanager/assets/typography-colors.css';

jha-list-item {
  --jha-list-item-border-display: none;
  --jha-list-item-horizontal-spacing: 0;
  margin-left: 0;
}

.list-border-top {
  border-top: 1px solid var(--jha-border-color);
}
</style>
