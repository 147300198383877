<script setup lang="ts">
import {requireInject} from '@/common/utils/InjectUtil'
import {PRODUCT_SELECTION_INJECTION} from '@/common/services/Products/productSelectionInjection'
import {computed} from 'vue'
import TypeCode from '@/common/data/TypeCode'
import {VsgFlexWrapper, VsgIconChevronRight, VsgListItem} from '@jack-henry/jha-vue-components'
import BltHelperLink from '@/common/components/bltHelperLink/bltHelperLink.vue'
import {BltSearch} from '@/common/services/Search/SearchFactory'
import {$inj} from '@/common/decorators/depinject'
import {IAccountTypeGroup} from "@/common/services/Products/accountGroupService";
import TFA_GROUPS from "@/common/components/productSelection/TfaGroupsEnum";
import BltAccountBlock from "@/common/components/bltAccountBlock/bltAccountBlock.vue";

const injection = requireInject(PRODUCT_SELECTION_INJECTION)
const bltSearch = $inj(BltSearch)

const props = defineProps<{
  group: TypeCode
}>()

const products = computed(() =>
  injection.values.productList.value.filter((product) => {
    return product.group === props.group.typeCode
  })
)

const productsSearched = computed(() => {
  return bltSearch.for(products.value, ['major', 'minor', 'description'], injection.values.searchTerm?.value ?? '')
})

const filterSubgroupWithAccount = (group: IAccountTypeGroup) => {
  return injection.values.productList.value
      .filter(product => product.group == group.typeCode)
      .length
}

const subgroupsWithAccounts = computed(() => {
  return injection.values.productSubgroups.value
      .get(props.group.typeCode)
      ?.filter(filterSubgroupWithAccount)
  ?? []
})

const subgroupsSearched = computed(() => {
  return bltSearch.for(subgroupsWithAccounts.value, ['typeCode', 'typeDescription'], injection.values.searchTerm?.value ?? '')
})

const hasAnyResults = computed(() => {
  return productsSearched.value.length > 0 || subgroupsSearched.value.length > 0
})

const icon = computed(() => {
  switch (props.group.typeCode) {
    case 'CHECKING':
    default:
      return 'jha-icon-check'
    case 'SAVINGS':
      return 'jha-icon-piggy-bank'
    case 'CD':
      return 'jha-icon-date'
  }
})

const subgroupIcon = (groupCode: string) => {
  switch (groupCode) {
    case TFA_GROUPS.ROTH_IRA:
    case TFA_GROUPS.TRADITIONAL_IRA:
      return 'IRA'
    case TFA_GROUPS.SINGLE_HSA:
    case TFA_GROUPS.FAMILY_HSA:
      return 'HSA'
  }
}

const selectSubgroup = (subgroup: IAccountTypeGroup) => {
  injection.methods.setSelectedSubgroup(subgroup)
  injection.methods.goToPane('PRODUCT_SUBGROUP')
}
</script>
<template>
  <div v-if="hasAnyResults"
       class="jh-font-heading-medium-1">{{ group.typeDescription }}</div>
  <vsg-list-item
    v-for="product in productsSearched"
    @click="injection.methods.addProduct(product)"
    :key="product.productId">
    <vsg-flex-wrapper>
      <component
        class="product-icon"
        :is="icon" />
      <div class="product-text-block">
        <div class="jh-font-body-regular-1 jha-text-dark">{{ product.description }}</div>
        &nbsp;
      </div>
      <blt-helper-link
          v-if="product.disclosureCode"
          :disclosure-code="product.disclosureCode" />
    </vsg-flex-wrapper>
    <vsg-icon-chevron-right slot="right" />
  </vsg-list-item>
</template>

<style scoped>
@import '../../../branchmanager/assets/typography.css';
@import '../../../branchmanager/assets/typography-colors.css';

jha-list-item {
  cursor: pointer;
  --jha-list-item-horizontal-spacing: 0;
  --jha-list-item-vertical-spacing: var(--jh-space-300);
}

jha-list-item:last-of-type {
  margin-bottom: var(--jh-space-400);
  border-bottom: 0;
}

.product-icon {
  fill: var(--jha-color-primary);
  margin-right: var(--jh-space-200);
}

jha-icon-chevron-right {
  fill: var(--account-card-secondary-background-color);
}

jha-list-item {
  --jha-list-item-horizontal-spacing: 0;
  margin-left: 0;
}

.product-text-block {
  flex: 1 1 auto;
  line-height: 0;
  padding-right: var(--jh-space-400);
}
</style>
