import {injectable} from 'inversify'
import {Service} from '@/common/dependency.configs'

@Service
@injectable()
export default class PreloadService {
  private invoked = false
  private MAX_CONNECTIONS = 2 as const

  /**
   * This will take in a list of resource URLs and load them via a pooling strategy to ensure we do not exhaust the
   * maximum number of connections afforded to us by browsers, this varies, but is usually 6.
   *
   * Note that this function can only be called once, as it is not currently designed to handle multiple resource groups.
   * having multiple instances of this running concurrently would defeat the purpose of the pooling strategy. When time
   * allows we should add an orchestration layer to manage multiple resource groups by a given priority.
   *
   * @param cacheName - The name of the cache to store the resources in provided to the native cache API
   * @param resources - A collection of URLs to be preloaded
   */
  async load(cacheName: string, resources: URL[]) {

    if (this.invoked)
      throw 'currently PrefetchService.load() can only be called once, additional work is required to support multiple resource groups.'

    this.invoked = true

    const inProgress: Set<Promise<void>> = new Set<Promise<void>>()

    const cache = await caches.open(cacheName)

    for (const item of resources) {

      //todo(mikol): It might make sense to add some retry / incremental backoff strategy here in the future.
      const promise = cache
        .add(item)
        .catch(() => console.warn(`Error caching ${cacheName} item: ${item.toString()}`))

      inProgress.add(promise)

      promise.finally(() => inProgress.delete(promise))

      if (inProgress.size >= this.MAX_CONNECTIONS) {
        await Promise.race(inProgress)
      }
    }
  }
}
