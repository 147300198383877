<script setup lang="ts">
import BltHelperLink from '@/common/components/bltHelperLink/bltHelperLink.vue'
import {computed} from 'vue'
import {isUndefined} from 'lodash'

const props = defineProps<{
  value?: boolean,
  disabled?: boolean
  channel: 'open' | 'branch'
  required?: boolean
}>()

const emits = defineEmits<{
  (e: 'toggle', value: boolean): void
}>()

const isEnabled = computed(() => {
  return !isUndefined(props.value) && props.value
})

const isDisabled = computed(() => {
  return !isUndefined(props.value) && !props.value
})
</script>

<template>
  <div class="radio-section-padding">
    <div>
       <span v-blt-ui-text="`${channel}_business_info_fincen_title`"/>
      {{props.required ? '*' : ''}}
      &nbsp;<blt-helper-link
        :disclosure-code="'FinCEN_HelperText'" />
    </div>
    <jha-form-radio-button
      data-cy="business-info-fincen-no-radio"
      :value="true"
      :disabled
      name="finCen"
      @checked="emits('toggle', $event.detail.value)"
      :checked="isEnabled">
      <span v-blt-ui-text="`${channel}_business_info_fincen_no_radio`"/>
    </jha-form-radio-button>
    <jha-form-radio-button
      data-cy="business-info-fincen-yes-radio"
      :value="false"
      :disabled
      name="finCen"
      @checked="emits('toggle', $event.detail.value)"
      :checked="isDisabled">
      <span v-blt-ui-text="`${channel}_business_info_fincen_yes_radio`"/>
    </jha-form-radio-button>
  </div>
</template>

<style scoped>
.radio-section-padding {
  padding-bottom: 0px;
  margin-top: var(--jh-space-800);
  margin-bottom: var(--jh-space-400);
}

jha-form-radio-button {
  margin-top: var(--jh-space-300);
}
</style>
