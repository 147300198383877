<template>
  <md-dialog :md-theme="themeName" aria-label="bltSignatureCtrl.title" class="sign-dialog">
    <div layout="column">
      <md-toolbar>
        <div class="md-toolbar-tools" layout="row" layout-align="space-between center">
          <h2>{{ title }}</h2>

          <md-button class="md-icon-button" ng-click="bltSignatureCtrl.cancel()">
            <md-icon md-font-icon="fa fa-times fa-2x" aria-label="Close dialog"></md-icon>
          </md-button>
        </div>
      </md-toolbar>

      <canvas blt-signature></canvas>

      <md-dialog-actions>
        <md-button ng-disabled="!bltSignatureCtrl.interactedWith" class="md-warn" ng-click="bltSignatureCtrl.reset()">
          Clear
        </md-button>

        <md-button
          ng-disabled="!bltSignatureCtrl.interactedWith"
          ng-click="bltSignatureCtrl.answer();"
          class="md-primary"
        >
          Continue
        </md-button>
      </md-dialog-actions>
    </div>
  </md-dialog>
</template>

<script>
import { defineComponent } from 'vue';
import { DisplayConstant } from '@/common/constant/DisplayConstant';

    export default defineComponent({
        name: "bltSignature",
        props: ["title"],
        data() {
          return {
            themeName: DisplayConstant.THEME_NAME
          }
        }
    })
</script>
