const UI_FIELDS_CATEGORIES = Object.freeze({
  OPEN: {
    ADDRESS: 21,
    DEMOGRAPHICS: 52,
    PERSON_INFO: 34,
    BUSINESS_INFO: 22,
    EMPLOYMENT: 28,
    EMPLOYMENT_HISTORY: 77,
    BENEFICIARY_INFO: 79,
    COLLATERAL_VEHICLE: 84,
    COLLATERAL_REALESTATE: 86,
    COLLATERAL_CASH: 88,
    COLLATERAL_OTHER: 90,
    LOAN_DEMOGRAPHICS: 92
  },
  BRANCH: {
    ADDRESS: 3,
    DEMOGRAPHICS: 53,
    PERSON_INFO: 9,
    BUSINESS_INFO: 49,
    EMPLOYMENT: 51,
    EMPLOYMENT_HISTORY: 78,
    BENEFICIARY_INFO: 80,
    COLLATERAL_VEHICLE: 85,
    COLLATERAL_REALESTATE: 87,
    COLLATERAL_CASH: 89,
    COLLATERAL_OTHER: 91,
    LOAN_DEMOGRAPHICS: 93
  },
});

export default UI_FIELDS_CATEGORIES;