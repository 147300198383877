import {inject, injectable} from 'inversify'
import {SingletonService} from '@/common/dependency.configs'
import HttpHandler from '@/common/services/connect/HttpHandler'
import type RestResponse from '@/common/data/RestResponse'

@injectable()
@SingletonService
export class ProductSubgroupDetailsService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  /**
   * @NOTE: This call will fail with 400 if there isn't a row in the DB yet.
   */
  get(productGroupCode: string): Promise<RestResponse<ProductSubgroupDetail>> {
    return this.httpHandler.get(`{{boltsbranch}}/productgroup/${productGroupCode}/group-details`)
  }

  post(productGroupCode: string, dto: ProductSubgroupDetail): Promise<RestResponse<ProductSubgroupDetail>> {
    return this.httpHandler.post(`{{boltsbranch}}/productgroup/${productGroupCode}/group-details`, dto)
  }

  put(productGroupCode: string, dto: ProductSubgroupDetail): Promise<RestResponse<ProductSubgroupDetail>> {
    return this.httpHandler.put(`{{boltsbranch}}/productgroup/${productGroupCode}/group-details/${dto.subGroupDetailId}`, dto)
  }

  delete(productGroupCode: string): Promise<RestResponse<void>> {
    return this.httpHandler.delete(`{{boltsbranch}}/productgroup/${productGroupCode}/group-details`)
  }
}

export interface ProductSubgroupDetail {
  subGroupDetailId?: string
  productGroupCode: string
  productFeatureDisclosureCode?: string
}
