<script setup lang="ts">
import {ref} from 'vue'
import ShadowDomService from '@/common/components/bltForm/shadowDomService'
import {$inj} from '@/common/decorators/depinject'

defineProps<{
  formStyle?: {[key: string]: string}
}>()

const shadowDomService = $inj(ShadowDomService)

const emit = defineEmits(['submit', 'validSubmit', 'invalidSubmit'])

const blurEvent = new Event('blur')

const form = ref<HTMLFormElement>()

function getAllInputs(): HTMLInputElement[] {
  const _form = form.value
  if (!_form) return []
  return shadowDomService.querySelectorAll('input,select,textarea', _form)
}

function isValid() {
  // todo(mikol): add a dirtied context concept that allows us to cache the validity of the form in case there are multiple
  // requests to check validity of the form...
  return getAllInputs().every((it) => it.validity.valid)
}

function reportValidity() {
  getAllInputs().forEach(it => it.reportValidity())
}

function isInvalid() {
  return !isValid()
}

function handleSubmit() {
  const formElem = form.value

  if (formElem === undefined) {
    console.error(
      'An error occurred during BltForm submission, expected a form element to exist, but it was undefined.'
    )
    return
  }

  const inputs: HTMLInputElement[] = shadowDomService.querySelectorAll('input,select,textarea', formElem)

  const isValid = inputs.every((it) => it.validity.valid)

  inputs.forEach((it) => it.dispatchEvent(blurEvent))

  if (isValid) {
    emit('validSubmit')
  } else {
    emit('invalidSubmit')
  }

  emit('submit')
}

defineExpose({form, isValid, reportValidity, isInvalid})
</script>

<template>
  <form
    :style="formStyle"
    @submit.prevent.stop="handleSubmit"
    ref="form">
    <slot />
  </form>
</template>

<style scoped></style>
