<script setup lang="ts">
import {Product} from '@/common/services/Products/ProductTypesService'
import {computed} from 'vue'
import TFA_GROUPS from '@/common/components/productSelection/TfaGroupsEnum'
import {
  VsgIconCar,
  VsgIconCheck,
  VsgIconCreditCard,
  VsgIconDate,
  VsgIconHeartbeat,
  VsgIconHome,
  VsgIconMoneybag,
  VsgIconPiggyBank
} from '@jack-henry/jha-vue-components'
import ProductSelectionIraIcon from '@/common/components/productSelection/product-selection-ira-icon.vue'
import LOAN_GROUPS from '@/common/components/productSelection/LoanGroupsEnum'

const props = defineProps<{
  product?: Product
  groupTypeCode?: string
  fillColor?: string
  size?: string
  color?: string
}>()
const group = computed(() => {
  return props.product?.accountTypeGroupCode || props.groupTypeCode
})

const icon = computed(() => {
  switch (group.value) {
    case LOAN_GROUPS.HOMEEQUITY:
      return VsgIconHome
    case LOAN_GROUPS.CREDITCARD:
      return VsgIconCreditCard
    case LOAN_GROUPS.VEHICLE:
      return VsgIconCar
    case LOAN_GROUPS.PERSONAL:
      return VsgIconMoneybag
    case TFA_GROUPS.TRADITIONAL_IRA:
    case TFA_GROUPS.ROTH_IRA:
      return ProductSelectionIraIcon
    case TFA_GROUPS.SINGLE_HSA:
    case TFA_GROUPS.FAMILY_HSA:
      return VsgIconHeartbeat
    case 'CHECKING':
      return VsgIconCheck
    case 'SAVINGS':
      return VsgIconPiggyBank
    case 'CD':
      return VsgIconDate
  }
})
</script>
<template>
  <div class="product-type-icon">
    <component
      :style="{fill: props.fillColor}"
      :color
      :size
      :is="icon" />
  </div>
</template>

<style scoped>
.product-type-icon {
  margin-right: var(--jh-space-200);
  display: flex;
  align-items: center;
}
</style>
