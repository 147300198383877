<template>
  <blt-modal-dialog
    @cancel="closeDisclosure"
    :showDialog="showDisclosureDialog"
    :aria-label="disclosure.disclosureTitle"
    :confirmDisabled="disclosureStatus"
    type="large-dialog">
    <template v-slot:dialog-header>
      <div class="w-100">
        <h1>{{ disclosure.disclosureTitle }}</h1>
        <h2>{{ secondaryTitle }}</h2>
      </div>
      <jha-button
        icon
        type="button"
        @click="closeDisclosure"
        class="reset">
        <jha-icon-close />
      </jha-button>
    </template>
    <template v-slot:dialog-content>
      <div
        :class="{resetLoader: loader, resetIframeContent: !loader}"
        class="w-100 container">
        <div
          v-if="errorMessages.length !== 0"
          class="blt-notification-wrapper pl-24 pr-24"
          id="workflowNotificationScroll">
          <blt-notification-emp :messages="errorMessages" />
        </div>
        <iframe
          v-if="!loader"
          id="disclosureId"
          title="disclosure"
          class="disclosure-content-iframe"
          ref="disclosureContent"
          v-blt-iframe="{
            html: disclosure.disclosure,
            dictionary: disclosure.data,
            disclosureStatus: disclosure.status
          }">
        </iframe>
        <div v-if="loader"><blt-contentloader :contentLoader="loader"></blt-contentloader></div>
      </div>
    </template>
    <template v-slot:dialog-footer>
      <template
        v-if="
          disclosure.status === STATE_MSG.INITIAL_DISCLOSURE.COMPLETE ||
          disclosure.status == STATE_MSG.INITIAL_DISCLOSURE.ACCEPTED
        ">
        <div
          v-if="disclosure.allowRevision"
          class="emp-disclosure">
          <button
            button-reset
            class="text-link emp-link-button"
            sync
            @click="editDisclosure"
            v-if="showEdit"
            :aria-label="editLabel"
            :disabled="editDisabled"
            :block="fullWidthButtons">
            {{ editText }}
          </button>
          <jha-button
            default
            sync
            @click="resetDisclosure"
            v-if="showReset"
            :aria-label="resetLabel"
            :disabled="resetDisabled"
            :block="fullWidthButtons"
            >{{ resetText }}
          </jha-button>
        </div>
      </template>
      <template
        v-if="
          disclosure.status === STATE_MSG.INITIAL_DISCLOSURE.PROVIDED ||
          disclosure.status == STATE_MSG.INITIAL_DISCLOSURE.REVISION
        ">
        <div class="emp-disclosure">
          <div v-if="disclosure.signatureTypeCode == STATE_MSG.INITIAL_DISCLOSURE.ESIGNATURE">
            <button
              button-reset
              class="text-link emp-link-button"
              sync
              @click="declineDisclosure"
              :aria-label="declineLabel"
              :disabled="declineDisabled"
              :block="fullWidthButtons">
              {{ declineText }}
            </button>
          </div>
          <jha-button
            sync
            @click="acceptDisclosure"
            :disabled="acceptDisabled"
            :block="fullWidthButtons">
            {{ isLastDisclosureToComplete ? acceptText : 'Accept and next' }}
          </jha-button>
        </div>
      </template>
    </template>
  </blt-modal-dialog>
</template>

<script lang="ts">
import {defineComponent} from 'vue'
import {DisclosureConstant} from '@/common/constant/DisclosureConstant'
import {BroadcastService, LanguageFactory} from '@/common/services/services.module'
import {$inj} from '@/common/decorators/depinject'
import {BranchLanguageKeysConstant} from '@/branchmanager/app/constant/BranchLanguageKeysConstant'
import STATE_MSG from '@/common/messages/state.messages'
import type IApplicant from '@/common/models/IApplicant'
import type {IErrorMessage} from '@/common/models/IErrorMessage'
import type {DisclosureDictionary} from '@/common/directives/bltIframe/DisclosureDictionary'
import type {BltIframeData} from '@/common/directives/bltIframe/BltIframeData'
import BltModalDialog from '@/common/components/bltDialog/bltModalDialog.vue'
import BltNotificationEmp from '@/branchmanager/app/components/bltNotificationEmp/bltNotificationEmp.vue'

interface Emits {
  (event: 'disclosureClosed'): void
  (event: 'acceptDisclosure', value: DisclosureDictionary): void
  (event: 'editDisclosure'): void
  (event: 'resetDisclosure'): void
  (event: 'declineDisclosure'): void
}

export default defineComponent({
  name: 'empDisclosureDialog',
  components: {BltNotificationEmp, BltModalDialog},
  emits: ['disclosureClosed', 'acceptDisclosure', 'editDisclosure', 'resetDisclosure', 'declineDisclosure'],
  props: {
    disclosure: {
      required: true,
      type: Object
    },
    secondaryTitle: {
      required: false,
      type: String
    },
    showDisclosureDialog: {
      required: true,
      type: Boolean
    },
    showReset: {
      type: Boolean,
      default: false
    },
    showdecline: {
      type: Boolean,
      default: false
    },
    showEdit: {
      type: Boolean,
      default: false
    },
    fullWidthButtons: {
      type: Boolean,
      default: false
    },
    editDisabled: {
      type: Boolean,
      default: false
    },
    showConfirm: {
      type: Boolean,
      default: false
    },
    editLabel: {
      type: String,
      default: ''
    },
    resetLabel: {
      type: String,
      default: ''
    },
    declineLabel: {
      type: String,
      default: ''
    },
    resetDisabled: {
      type: Boolean,
      default: false
    },
    acceptDisabled: {
      type: Boolean,
      default: false
    },
    loader: {
      type: Boolean,
      default: false
    },
    declineDisabled: {
      type: Boolean,
      default: false
    },
    isFinalDisclosure: {
      type: Boolean,
      default: false
    },
    isAccountApplicantDisclosure: {
      type: Boolean,
      default: false
    },
    isLastDisclosureToComplete: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array<IErrorMessage>,
      required: true
    }
  },
  data() {
    return {
      DisclosureConstant,
      acceptText: '' as string,
      editText: '' as string,
      resetText: ' ' as string,
      declineText: '' as string,
      STATE_MSG,
      applicantData: {} as IApplicant
    }
  },
  setup() {
    const languageFactory = $inj(LanguageFactory)
    const broadcastService = $inj(BroadcastService)
    return {
      languageFactory,
      broadcastService
    }
  },
  async created() {
    if (this.isFinalDisclosure) {
      ;[this.acceptText, this.editText, this.resetText] = await Promise.resolve([
        this.languageFactory.getSynchronous(BranchLanguageKeysConstant.BRANCH_FINAL_DISCLOSURE_BUTTON_ACCEPT),
        this.languageFactory.getSynchronous(BranchLanguageKeysConstant.BRANCH_FINAL_DISCLOSURE_BUTTON_EDIT),
        this.languageFactory.getSynchronous(BranchLanguageKeysConstant.BRANCH_FINAL_DISCLOSURE_BUTTON_RESET)
      ])
    } else if (this.isAccountApplicantDisclosure) {
      ;[this.acceptText, this.editText, this.resetText] = await Promise.resolve([
        this.languageFactory.getSynchronous(
          BranchLanguageKeysConstant.BRANCH_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_ACCEPT
        ),
        this.languageFactory.getSynchronous(BranchLanguageKeysConstant.BRANCH_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_EDIT),
        this.languageFactory.getSynchronous(BranchLanguageKeysConstant.BRANCH_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_RESET)
      ])
    } else {
      ;[this.acceptText, this.editText, this.resetText, this.declineText] = await Promise.resolve([
        this.languageFactory.getSynchronous(BranchLanguageKeysConstant.BRANCH_INITIAL_DISCLOSURE_BUTTON_SUBMIT),
        this.languageFactory.getSynchronous(BranchLanguageKeysConstant.BRANCH_INITIAL_DISCLOSURE_BUTTON_EDIT),
        this.languageFactory.getSynchronous(BranchLanguageKeysConstant.BRANCH_INITIAL_DISCLOSURE_BUTTON_RESET),
        this.languageFactory.getSynchronous(BranchLanguageKeysConstant.BRANCH_INITIAL_DISCLOSURE_BUTTON_DECLINE)
      ])
    }
  },
  computed: {
    disclosureStatus(): boolean {
      return this.disclosure.status === DisclosureConstant.COMPLETE
    }
  },
  methods: {
    getBltIframeData(): BltIframeData {
      return (this.$refs.disclosureContent as any).getDictionary()
    },
    acceptDisclosure(): void {
      this.$emit('acceptDisclosure', this.getBltIframeData())
    },
    closeDisclosure(): void {
      this.$emit('disclosureClosed')
    },
    editDisclosure(): void {
      this.$emit('editDisclosure')
    },
    resetDisclosure(): void {
      this.$emit('resetDisclosure')
    },
    declineDisclosure(): void {
      this.$emit('declineDisclosure')
    }
  }
})
</script>
<style scoped>
.container {
  min-height: calc(86vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.disclosure-content-iframe {
  width: 100%;
  border: 0;
  overflow: hidden;
  padding: 24px;
  flex-grow: 1;
}
.emp-disclosure {
  display: flex;
}

.emp-link-button {
  font-size: 14px;
  font-weight: 500;
  padding: 10px 16px;
  margin-right: 8px;
  cursor: pointer;
  color: var(--primary-button-color);
}

.disclosure-content-iframe input {
  width: 100%;
}
.resetIframeContent {
  width: 100%;
}

h2 {
  color: var(--jha-text-base);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: 0;
}

@media (max-width: 468px) {
  .disclosure-content-iframe {
    min-height: calc(82vh - 90px);
    padding: 16px;
  }
}
@media (max-width: 375px) {
  .disclosure-content-iframe {
    min-height: calc(77vh - 90px);
  }
}
@media (max-width: 360px) {
  .disclosure-content-iframe {
    min-height: calc(76vh - 90px);
  }
}
</style>
