import {inject, injectable} from 'inversify'
import {SingletonService} from '@/common/dependency.configs'
import {HttpHandler} from '@/common/services/services.module'
import type RestResponse from '@/common/data/RestResponse'
import SERVICE_PATH_CONSTANTS from '@/common/constant/servicePathConstants'

@injectable()
@SingletonService
export default class AccountLoanPurposeService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getAll(
    workspaceUUID: string,
    enrollmentId: number,
    accountId: number
  ): Promise<RestResponse<Array<AccountLoanPurpose>>> {
    return this.httpHandler.get(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/loan-purposes`
    )
  }

  put(
    workspaceUUID: string,
    enrollmentId: number,
    accountId: number,
    dto: AccountLoanPurpose
  ): Promise<RestResponse<AccountLoanPurpose>> {
    return this.httpHandler.put(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/loan-purposes/${dto.purposeTypeCode}`,
      dto
    )
  }

  post(
    workspaceUUID: string,
    enrollmentId: number,
    accountId: number,
    dto: AccountLoanPurpose
  ): Promise<RestResponse<AccountLoanPurpose>> {
    return this.httpHandler.post(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/loan-purposes/${dto.purposeTypeCode}`,
      dto
    )
  }

  delete(
    workspaceUUID: string,
    enrollmentId: number,
    accountId: number,
    purposeTypeCode: string
  ): Promise<RestResponse<void>> {
    return this.httpHandler.delete(
      `${SERVICE_PATH_CONSTANTS.WORKSPACE_URL_TEMPLATE}/${workspaceUUID}/enrollment/${enrollmentId}/account/${accountId}/loan-purposes/${purposeTypeCode}`
    )
  }
}

export interface AccountLoanPurpose {
  accountId: number
  purposeTypeCode: string
}
