export default {
  INITIAL_DISCLOSURE: "INITIAL_DISCLOSURE",
  PERSON_INFO: "PERSON_INFO",
  OCR: "OCR",
  PHONE: "PHONE",
  EMAIL: "EMAIL",
  IDENTIFICATION: "IDENTIFICATION",
  ADDRESS: "ADDRESS",
  ALL: "ALL",
  DEMOGRAPHICS: "DEMOGRAPHICS",
  EMPLOYMENT: "EMPLOYMENT",
  EMPLOYMENT_HISTORY: "EMPLOYMENT_HISTORY",
  PREQUALIFICATION: "PREQUALIFICATION",
  BUSINESS_INFO: "BUSINESS_INFO",
  DOCUMENT: "DOCUMENT",
  ACCOUNT_APPLICANT_DISCLOSURE: "ACCOUNT_APPLICANT_DISCLOSURE",
  INCOME: "INCOME", // todo(mikol): this does not exist on the API yet...
  EXPENSES: "EXPENSES",
  ADDITIONAL_INCOME: "ADDITIONAL_INCOME",
  LOAN_DECLARATION_QUESTIONS: "LOAN_DECLARATION_QUESTIONS",
  ADDRESS_HISTORY: "ADDRESS_HISTORY",
  ASSETS: "ASSETS",
  LOAN_DEMOGRAPHICS: "LOAN_DEMOGRAPHICS",
} as const;