import {inject, injectable} from 'inversify'
import {Service, SingletonService} from '@/common/dependency.configs'
import HttpHandler from '@/common/services/connect/HttpHandler'
import type RestResponse from '@/common/data/RestResponse'
import {AxiosHeaders} from "axios";

@injectable()
@SingletonService
export class ProductsSubgroupTaxplanService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  get(typeCode: string): Promise<RestResponse<ProductsSubgroupTaxplan>> {
    return this.httpHandler.get(`{{boltsbranch}}/tax-plan/${typeCode}`)
  }

  put(taxPlanId: string, dto: ProductsSubgroupTaxplan): Promise<RestResponse<ProductsSubgroupTaxplan>> {
    return this.httpHandler.put(`{{boltsbranch}}/tax-plan/${taxPlanId}`, dto)
  }

  getTaxPlanInfo(): Promise<RestResponse<Array<TaxPlanInfo>>> {
    return this.httpHandler.get(`{{boltsbranch}}/tax-plan-info`)
  }
}

export interface TaxPlanInfo {
  taxPlanUuid?: string
  coreCode?: string
  description?: string
}

export interface ProductsSubgroupTaxplan {
  typeCode: string
  subgroupCode: string
  enabled: boolean
  typeDescription: string
  displayOrder: number
  disclosureCode?: string
  previousYearContribCutoffDate: string
  validateFundingIntentionContribLimits: boolean
  validateFundingContribLimits: boolean
  currentYearContribLimit: number
  previousYearContribLimit: number
  currentYearCatchupContribLimit: number
  previousYearCatchupContribLimit: number
  currentYearCatchupAge: number
  previousYearCatchupAge: number
  coreCode?: string,
  feeCode?: string,
  includeInterestDistributionFlag?: boolean,
}
