import { AxiosHeaders } from "axios"

export interface Detail {
  code: string,
  message: string,
}

export type DetailType = 'info' | 'warning' | 'error'

export interface TypedDetail extends Detail{
    type: DetailType,
}

export interface RestResponseData<T> {
    payload: T,
    resultCode: string,
    message?: string,
    success: boolean,
    errors?: Detail[],
    warnings?: Detail[],
    infos?: Detail[],
}
export default interface RestResponse<T> {
    data: RestResponseData<T>,
    headers: AxiosHeaders
}

const clientErrorCode = "CLIENT_ERROR"

export function clientError(message: string): RestResponse<null> {
    return {
        data: {
            payload: null,
            resultCode: clientErrorCode,
            message: message,
            success: false,
            errors: [{code: clientErrorCode, message}],
            warnings: [],
            infos: [],
        }, 
        headers: new AxiosHeaders
    }
}