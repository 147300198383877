import {inject, injectable} from 'inversify'
import {SingletonService} from '@/common/dependency.configs'
import {HttpHandler} from '@/common/services/services.module'
import type RestResponse from '@/common/data/RestResponse'
import type CacheableAxiosRequestConfig from '@/common/services/connect/CacheableAxiosRequestConfig'

@injectable()
@SingletonService
export default class ProductLoanPurposeService {
  constructor(@inject(HttpHandler) private httpHandler: HttpHandler) {}

  getAll(accountTypeId: number, options: CacheableAxiosRequestConfig = {}): Promise<RestResponse<Array<ProductLoanPurpose>>> {
    return this.httpHandler.get(`{{boltsbranch}}/accounttype/${accountTypeId}/loan-purposes`, options)
  }

  post(accountTypeId: number, dto: ProductLoanPurpose): Promise<RestResponse<ProductLoanPurpose>> {
    return this.httpHandler.post(`{{boltsbranch}}/accounttype/${accountTypeId}/loan-purposes`, dto)
  }

  delete(accountTypeId: number, loanPurposeTypeCode: string): Promise<RestResponse<ProductLoanPurpose>> {
    return this.httpHandler.delete(`{{boltsbranch}}/accounttype/${accountTypeId}/loan-purposes/${loanPurposeTypeCode}`)
  }
}

export interface ProductLoanPurpose {
  accountTypeId: number
  purposeTypeCode: string
}
