<script setup lang="ts">
import {computed} from "vue";
import {isUndefined} from "lodash";

const props = defineProps<{
  channel: 'open' | 'branch'
  disabled?: boolean
  required?: boolean
  value?: boolean
}>()

const emits = defineEmits<{
  (e: 'toggle', value: boolean): void
}>()

const isEnabled = computed(() => {
  return !isUndefined(props.value) && props.value
})

const isDisabled = computed(() => {
  return !isUndefined(props.value) && !props.value
})
</script>

<template>
  <div class="radio-section-padding">
    <span
      style="font-weight: 400"
      v-blt-ui-text="`${channel}_business_info_forprofit_label`"></span>
    {{ required ? '*' : '' }}

    <jha-form-radio-button
      data-cy="business-info-for-profit-yes-radio"
      :disabled
      class="business-info-radio"
      value="true"
      name="forProfit"
      ref="forProfit"
      @checked="emits('toggle', true)"
      :checked="isEnabled">
      <span v-blt-ui-text="`${channel}_business_info_forprofit_true_label`"></span>
    </jha-form-radio-button>
    <jha-form-radio-button
      data-cy="business-info-for-profit-no-radio"
      :disabled
      class="business-info-radio"
      value="false"
      name="forProfit"
      ref="forProfit"
      @checked="emits('toggle', false)"
      :checked="isDisabled">
      <span v-blt-ui-text="`${channel}_business_info_forprofit_false_label`"></span>
    </jha-form-radio-button>
  </div>
</template>

<style scoped>
.radio-section-padding {
  padding-bottom: 0px;
  margin-top: var(--jh-space-800);
  margin-bottom: var(--jh-space-400);
}

jha-form-radio-button {
  margin-top: var(--jh-space-300);
}
</style>